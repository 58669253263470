$primaryWhite: #FFFFFF;
$primaryYellow: #FFD200;
$primaryBlack: #000000;
$primaryWhite: #FFFFFF;
$primaryGrey: #9E98A3;

$bgGrey: #9E98A310;

$disabledGrey: #F4F4F5;
$darkGrey: #4c4c4c;

$errorRed: #F93C10;
$passGreen: #43B129;

$c-grey-f4f4f5: #F4F4F5; // border color
$c-grey-ebeaec: #EBEAEC; // background color

$borderGrey: #00000010;
$borderLightGrey: #ECEAED;