@import "../../assets/styles/variables.module";

.round_page {
  background: #ffffff;
  & .container {
    padding: 0 40px;
  }
  &__preloader {
    min-height: 70vh;
  }
  &__inner {
    max-width: 1200px;
    margin: 0 auto;
    background: #FFF;
    padding-top: 140px;
    padding-bottom: 220px;
  }
  &__main {
    position: relative;
    background: url("../../assets/images/bg_round_active.svg") no-repeat;
    background-position: 116% 50%;
    &_inner {
      max-width: 560px;
    }
  }

  &__desc {
    margin-top: 16px;
  }

  &__countdown {
    margin-top: 20px;
    & > div {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 2px;
    }
  }

  &__investor_link {
    &.one {
      margin-top: 36px;
    }
    &.two {
      margin: 0 auto;
      margin-top: 80px;
    }
  }

  &__founders {
    margin-top: 80px;
    &_list {
      display: flex;
      gap: 16px;
      margin-top: 20px;
    }
    &_card {
      background: #FFFFFF;
      border: 2px solid $borderLightGrey;
      border-radius: 8px;
      padding: 40px;
      width: 100%;
      & h3 {
        margin-top: 7px;
      }
      &_avatar {

      }
      &_avatar:not(:first-child) {
        margin-left: -15px;
      }
      &_site {
        margin-top: 2px;
      }
      &_sections {
        margin-top: 8px;
        li {
          position: relative;
          padding-left: 20px ;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
          &::after {
            content: '';
            width: 8px;
            height: 8px;
            background: $primaryYellow;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }
      }

      &:last-child {
        display: flex;
        .scr-button {
          max-width: 202px;
          min-width: 202px;
          min-height: 48px;
          margin: 16px auto 0;
        }
      }
      &_content {
        margin: auto;
        text-align: center;
      }
    }
  }

  &__fundraising {
    margin-top: 80px;
    .invest-progress-bar {
      margin-top: 16px;
    }
  }

  &__why {
    margin-top: 80px;
    $size: 362px;

    &_circles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
    }
    &_circle {
      width: $size;
      min-width: $size;
      height: $size;
      border: 2px solid $primaryYellow;
      border-radius: 50%;
      background: #ffffff;
      overflow: hidden;
      display: flex;
      cursor: pointer;
      &:hover {
        background: rgba(255, 210, 0, 0.04);
      }
      &_content {
        margin: auto;
        text-align: center;
        white-space: pre-line;
        & p {
          margin-top: 4px;
          color: #4D4D4D;
        }
        & a {
          margin-top: 8px;
          display: inline-block;
        }
      }
    }
  }

  &__faq {
    &_inner {
      background: #F5F5F6;
    }
  }
}