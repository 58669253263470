.simple-dropdown {
  position: relative;
  &.active {
    & > .simple-dropdown__label svg {
      transform: rotate(90deg);
      transition: transform 0.2s linear;
    }
    & > .simple-dropdown__content {
      max-height: 10000vh;
      transition: max-height 5s ease-in;
    }
  }
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 20px);
    height: 1px;
    background: #E4E2E5;
  }

  &__label {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    padding: 22px 0;
    color: #000;
    &:hover {
      opacity: 0.7;
    }
    & p {  cursor: pointer; };
    & svg {
      cursor: pointer;
      margin-right: 20px;
      margin-top: 6px;
      transition: transform 0.2s linear;
    }
  }
  &__content {
    overflow: hidden;
    max-height: 0;
    //transition: max-height 3s ease-out;
    & a {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
    &-inner {
      color: #4A4A4A;
      //padding: 10px 0 10px 24px;
      padding-left: 24px;
      & p:not(:first-child) {
        margin-top: 15px;
      }
      &-doubleheight {
        height: 28px;
      }
    }
  }
}